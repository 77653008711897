import React, {useState} from 'react';
import { Link, navigate } from "gatsby";
import ReactPlayer from 'react-player/lazy';
import Modal from 'react-modal';

import Box from '@mui/material/Box';
import Layout from '../components/layout';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import GenericButton from '../components/shared/genericButton';
import BendableHeroBG from '../components/librariesPage/bendableHeroBG';
import ContactFormImageBG from '../components/librariesPage/contactFormImageBG';
import ContactForm from '../components/librariesPage/contactForm';
import BendableLabsLogo from '../components/footer/BendableLabsLogo';

import BendableOnPhones from '../images/bendable-on-three-phones.png';
import FindYourBendable from '../components/findYourBendable/FindYourBendable';;

const Bendable2point0Page = (props) => {

  const isLarge = useMediaQuery('(min-width:1200px)');


  return (
    <React.Fragment></React.Fragment>
  );
};

export default Bendable2point0Page;